import * as React from "react"
import { useState, useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"

const formatNumber = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

const calculateDiscountedPrice = (impressions, addon1) => {
    const basePrice = 0.019
    let costPerImpression = basePrice

    if (addon1) {
        costPerImpression += 0.002
    }

    let addonCost = impressions * (costPerImpression - basePrice)
    let baseCost = impressions * basePrice
    let discount = 0

    if (impressions > 75000000) {
        baseCost *= 0.7 // 30% discount
        discount = 30
    } else if (impressions > 50000000) {
        baseCost *= 0.75 // 25% discount
        discount = 25
    } else if (impressions > 30000000) {
        baseCost *= 0.8 // 20% discount
        discount = 20
    } else if (impressions > 15000000) {
        baseCost *= 0.85 // 15% discount
        discount = 15
    } else if (impressions > 10000000) {
        baseCost *= 0.9 // 10% discount
        discount = 10
    } else if (impressions > 2000000) {
        baseCost *= 0.95 // 5% discount
        discount = 5
    } // < 2.000.000 has no discount

    let totalCost = baseCost + addonCost
    return { totalCost: Math.round(totalCost), discount }
}

export function PricingComponent(props) {
    const [impressions, setImpressions] = useState(0)
    const [addon1, setAddon1] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [discount, setDiscount] = useState(0)

    useEffect(() => {
        if (props.fontFamily) {
            const link = document.createElement("link")
            link.href = `https://fonts.googleapis.com/css2?family=${props.fontFamily.replace(
                / /g,
                "+"
            )}&display=swap`
            link.rel = "stylesheet"
            document.head.appendChild(link)
        }
    }, [props.fontFamily])

    const updateTotalPrice = (newImpressions, newAddon1) => {
        const { totalCost, discount } = calculateDiscountedPrice(
            newImpressions,
            newAddon1
        )
        setTotalPrice(totalCost)
        setDiscount(discount)
    }

    const handleImpressionsChange = (event) => {
        const value = parseInt(event.target.value.replace(/\./g, ""), 10) || 0
        setImpressions(value)
        updateTotalPrice(value, addon1)
    }

    const handleToggle1Change = (event) => {
        const checked = event.target.checked
        setAddon1(checked)
        updateTotalPrice(impressions, checked)
    }

    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                alignItems: "start",
                height: "auto",
            }}
        >
            <div style={{ width: "30%", textAlign: "left" }}>
                <input
                    type="text"
                    value={formatNumber(impressions)}
                    onChange={handleImpressionsChange}
                    style={{
                        padding: "8px 10px",
                        fontSize: props.inputFontSize,
                        borderRadius: "8px",
                        border: "1px solid #dadada",
                        width: "100%",
                        fontFamily: props.fontFamily,
                    }}
                />
            </div>
            <div
                style={{
                    width: "25%",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    marginLeft: "20px",
                    gap: "10px",
                }}
            >
                <label
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0px",
                        position: "relative",
                    }}
                >
                    <input
                        type="checkbox"
                        checked={addon1}
                        onChange={handleToggle1Change}
                        style={{
                            appearance: "none",
                            width: "60px",
                            height: "30px",
                            backgroundColor: addon1
                                ? props.toggle1ActiveColor
                                : "#eaeaea",
                            borderRadius: "30px",
                            outline: "none",
                            cursor: "pointer",
                            transition: "background-color 0.3s",
                            position: "relative",
                        }}
                    />
                    <span
                        style={{
                            position: "absolute",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            top: "5px",
                            left: addon1 ? "36px" : "7px",
                            transition: "left 0.3s",
                        }}
                    />
                </label>
            </div>
            <div
                style={{
                    width: "45%",
                    textAlign: "left",
                    justifyContent: "start",
                    lineHeight: "1",
                }}
            >
                <div
                    style={{
                        fontSize: props.priceFontSize,
                        fontWeight: "900",
                        color: props.priceColor,
                        fontFamily: props.fontFamily,
                    }}
                >
                    {formatNumber(totalPrice)} {props.currency}
                </div>
                {discount > 0 && (
                    <div
                        style={{
                            fontSize: props.discountFontSize,
                            marginTop: "10px",
                            fontWeight: "500",
                            color: props.discountColor,
                            fontFamily: props.fontFamilyBody,
                        }}
                    >
                        {discount}% magnafsláttur af grunnverði
                    </div>
                )}
            </div>
        </div>
    )
}

addPropertyControls(PricingComponent, {
    priceFontSize: {
        type: ControlType.Number,
        title: "Price Font Size",
        defaultValue: 24,
    },
    priceColor: {
        type: ControlType.Color,
        title: "Price Color",
        defaultValue: "#000",
    },
    currency: {
        type: ControlType.String,
        title: "Currency",
        defaultValue: "kr.",
    },
    inputFontSize: {
        type: ControlType.Number,
        title: "Input Font Size",
        defaultValue: 16,
    },
    fontFamily: {
        type: ControlType.String,
        title: "Font Family",
        defaultValue: "Arial, sans-serif",
    },
    fontFamilyBody: {
        type: ControlType.String,
        title: "Font Family",
        defaultValue: "Arial, sans-serif",
    },
    toggle1ActiveColor: {
        type: ControlType.Color,
        title: "Toggle 1 Active Color",
        defaultValue: "#FFA500",
    },
    toggle2ActiveColor: {
        type: ControlType.Color,
        title: "Toggle 2 Active Color",
        defaultValue: "#1E90FF",
    },
    discountFontSize: {
        type: ControlType.Number,
        title: "Discount Font Size",
        defaultValue: 14,
    },
    discountColor: {
        type: ControlType.Color,
        title: "Discount Color",
        defaultValue: "#FF0000",
    },
})
